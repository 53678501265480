import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";

const { t } = useI18nUtils();
const { canViewVerticalNavMenuHeader } = useAclUtils();

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  created() {
    console.log(this.item);
  },
  render(h) {
    console.log(h);
    const span = h("span", {}, t(this.item.header));
    const icon = h("feather-icon", {
      props: { icon: "MoreHorizontalIcon", size: "18" },
    });
    if (canViewVerticalNavMenuHeader(this.item)) {
      var ruta = this.item.route;
      return h(
        "li",
        {
          class: "navigation-header text-truncate",
          on: {
            click: function() {
              console.log(ruta);
              window.location.href = "/admin/" + ruta;
            },
          },
        },
        [span, icon]
      );
    }
    return h();
  },
};
