<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
  >
    <template #button-content>
      <!--  <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      /> -->

      <span class="ml-20 text-body">{{ currentLocale.name }}</span>
      <feather-icon size="16" icon="ChevronDownIcon" />
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLanguage(localeObj.locale)"
    >
      <!--  <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      /> -->
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { config } from "@/shared/app.config";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
    ...mapGetters({
      user: "auth/getUser",
    }),
  },

  methods: {
    ...mapMutations({
      setCurrentLanguage: "app/setCurrentLanguage",
    }),
    ...mapActions({
      searchUsersClient: "users/searchUsersClient",
      setLanguageAction: "users/setLanguage",
    }),

    async setLanguage(idioma) {
      console.log(idioma);

      var idUser = this.user.usuario_id;
      console.log(idUser);

      await this.setLanguageAction({
        idioma,
        idUser,
      }).then(() => {
        this.setCurrentLanguage(idioma);
      });
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = config.locales;
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style></style>
