<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <buttonChangeCustomer :class="'ml-2 mr-2'" v-if="user.rol_id == 1" />

      <div
        v-if="user.rol_id != 1"
        class="d-sm-flex user-nav ml-50 mr-50"
        style="display: flex;flex-direction: column; "
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ user.nombre }}
        </p>
        <span class="user-status">{{ user.rol_nombre }}</span>
      </div>
      <div
        v-else
        class="d-sm-flex d-none user-nav ml-50 mr-50"
        style="display: flex;flex-direction: column; "
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ user.nombre }}
        </p>
        <span class="user-status">{{ user.rol_nombre }}</span>
      </div>
      <Logout />
      <Locale />
      <!--  <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.nombre }}
            </p>

            <span class="user-status">{{ user.rol_nombre }}</span>
          </div>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $i18n.messages.CerrarSesion }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="logout">
            <feather-icon icon="LogOutIcon" size="21" />
          </b-link>
        </li>
      </ul> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import buttonChangeCustomer from "@core/layouts/components/ButtonChangeCustomer.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import Logout from "@core/layouts/components/app-navbar/components/Logout.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    buttonChangeCustomer,
    Locale,
    Logout,
    DarkToggler,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: "",
    };
  },

  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapMutations({
      logout: "auth/logout",
    }),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      client: "customers/getCurrentCustomer",
    }),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
