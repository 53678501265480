<template>
  <p class="clearfix mb-0" style="height:50px">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://duneceramics.com/" target="_blank"
        >DUNE Cerámica</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        href="https://duneceramics.com/aviso-legal/"
        target="_blank"
        >{{ $i18n.messages.legal1 }}</b-link
      >
      <span class="ml-25"> </span>
      <b-link
        class="ml-25"
        href="https://duneceramics.com/terminos-y-condiciones/"
        target="_blank"
        >{{ $i18n.messages.legal4 }}</b-link
      >
      <span class="ml-25" style="font-weight:bold;">VERSION 1.3.4 </span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
