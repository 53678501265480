import i18n from "@/libs/i18n";

export default [
  {
    title: "Catálogo",
    /*  route: "home", */
    icon: "BookOpenIcon",
    header: "Gestor",
  },
  {
    title: i18n.messages.Productos,
    route: "products",
    icon: "LayersIcon",
  },
  /*  {
    title: i18n.messages.Pedidos,
    route: "orders",
    icon: "BookOpenIcon",
  }, */
  {
    title: "Usuarios",
    route: "userspendent",
    icon: "HomeIcon",
    header: "Usuarios",
  },
  {
    title: "Registrados",
    route: "usersregistered",
    icon: "UserPlusIcon",
  },
  {
    title: "Pendientes",
    route: "userspendent",
    icon: "UserPlusIcon",
  },
  {
    title: "Activos",
    route: "usersactive",
    icon: "UserCheckIcon",
  },
  /* {
    title: "Inactivos",
    route: "usersinactive",
    icon: "UserMinusIcon",
  }, */
  {
    title: "Bloqueados",
    route: "usersblock",
    icon: "UserXIcon",
  },
  /*    {
    title: "Clientes",
    route: "userscli",
    icon: "UsersIcon",
  }, */
  {
    title: "Comerciales",
    route: "userscom",
    icon: "UsersIcon",
  },
  /*   {
    title: "Comerciales ERP",
    route: "userscomerp",
    icon: "UsersIcon",
  }, */
];
