<template>
    <div>
        <b-nav-item-dropdown
                id="dropdown-grouped"
                variant="link"
                right
        >
            <template #button-content>
                <feather-icon size="16" icon="ChevronDownIcon" />
            </template>
            <b-dropdown-item @click="logout" >
                <span class="ml-50">{{ $i18n.messages.CerrarSesion }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeAccountModalShow" >
                <span class="ml-50 text-danger">{{ $i18n.messages.RemoveAccount }}</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-modal ref="modalRemove" hide-footer>
            <div class="my-2">
                <p class="h2 text-center">
                    {{ $i18n.messages.WantRemoveAccount }}
                </p>
                <div class="mt-2 d-flex justify-content-center">
                    <b-button variant="secondary" class="ml-2" @click="hideModalRemove">
                        {{ $i18n.messages.Cancel }}
                    </b-button>
                    <b-button
                        variant="primary"
                        class="ml-2"
                        @click="removeAccountConfirm"
                    >
                        {{ $i18n.messages.Accept }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {BNavItemDropdown, BDropdownItem, BButton, BModal} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
      BButton,
    BNavItemDropdown,
    BDropdownItem,
      BModal
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },

  methods: {
      ...mapMutations({
          logout: "auth/logout",
      }),
    ...mapActions({
        removeAccount: "auth/removeAccount",
    }),
    removeAccountModalShow(){
        this.$refs["modalRemove"].show();
    },
    hideModalRemove() {
        this.$refs["modalRemove"].hide();
    },
    removeAccountConfirm() {
        this.removeAccount({
            toast: this.$toast,
        });
    },
  }
};
</script>

<style></style>
